export default [
  {
    path: '/cetak-ajuan-bop/:id',
    name: 'route-cetak-ajuan-bop',
    // component: () => import('@/views/pages/cetak/List.vue'),
    // meta: {
    //     module: 'cetak',
    //     authRequired: true,
    //     pageTitle: 'cetak',
    //     breadcrumb: [
    //         {
    //             text: 'cetak',
    //             active: true,
    //         }
    //     ],
    // }
  },
]
