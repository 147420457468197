export default [
  {
    path: '/owner/teritori',
    name: 'route-owner-teritori',
    component: () => import('@/views/pages/owner/teritori/List.vue'),
    meta: {
      module: 'Teritori Wilayah',
      authRequired: true,
      pageTitle: 'Teritori Wilayah',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/owner/pengiriman',
    name: 'route-owner-pengiriman',
    component: () => import('@/views/pages/owner/transaksicabang/List.vue'),
    meta: {
      module: 'Pengiriman',
      authRequired: true,
      pageTitle: 'Pengiriman',
      breadcrumb: [
        {
          text: 'Pengiriman',
          active: true,
        },
      ],
    },
  },
  {
    path: '/owner/pengiriman/detail/:id',
    name: 'route-owner-pengiriman-detail',
    component: () => import('@/views/pages/owner/transaksicabang/Detail.vue'),
    meta: {
      parent: '/owner/pengiriman',
      module: 'Pengiriman',
      authRequired: true,
      pageTitle: 'Info Pengiriman',
      breadcrumb: [
        {
          text: 'Pengiriman',
          to: '/owner/pengiriman',
        },
        {
          text: 'Info',
          active: true,
        },
      ],
    },
  },
  {
    path: '/owner/pengiriman/tambah',
    name: 'route-owner-pengiriman-tambah',
    component: () => import('@/views/pages/owner/transaksicabang/Store.vue'),
    meta: {
      parent: '/owner/pengiriman',
      module: 'Pengiriman',
      authRequired: true,
      pageTitle: 'Tambah Barang',
      breadcrumb: [
        {
          text: 'Pengiriman',
          to: '/owner/pengiriman',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/owner/pengiriman/edit/:id',
    name: 'route-owner-pengiriman-edit',
    component: () => import('@/views/pages/owner/transaksicabang/StoreEdit.vue'),
    meta: {
      parent: '/owner/pengiriman',
      module: 'Pengiriman',
      authRequired: true,
      pageTitle: 'Edit Pengiriman',
      breadcrumb: [
        {
          text: 'Pengiriman',
          to: '/owner/pengiriman',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/owner/signa',
    name: 'route-owner-signa',
    component: () => import('@/views/pages/owner/signa/List.vue'),
    meta: {
      module: 'Signa',
      authRequired: true,
      pageTitle: 'Signa',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/owner/keterangan-resep',
    name: 'route-owner-keterangan-resep',
    component: () => import('@/views/pages/owner/ket_resep/List.vue'),
    meta: {
      module: 'Keterangan Resep',
      authRequired: true,
      pageTitle: 'Keterangan Resep',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

]
