import service from '@/services/index'

// Module Vuex karyawan
export default {
  namespaced: true,
  state: {
    datas: [],
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
  },
  actions: {
    async changePassword({}, payload = {}) {
      try {
        const config = {
          url: `/user/ganti-password/${payload.id}`,
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async resetPassword({}, payload = {}) {
      try {
        const config = {
          url: '/user/reset-password',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async resetPin({}, payload = {}) {
      try {
        const config = {
          url: '/user/reset-pin',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/user',
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          params,
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/input/user',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
