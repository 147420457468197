import service from '@/services'

export default {
  state: {
    request_mutations: [],
    entry_mutations: [],
    current_mutasi: null,
    gudang_tujuans: [],
  },
  getters: {
    searchRequestMutation: state => id => state.request_mutations.find(item => item.id == id),
    searchEntryMutation: state => id => state.entry_mutations.find(item => item.id == id),
  },
  mutations: {
    SET_CURRENT_MUTATION(state, data) {
      state.current_mutasi = data
    },
    SET_REQUEST_MUTATION(state, datas) {
      state.request_mutations = datas
    },
    SET_ENTRY_MUTATION(state, datas) {
      state.entry_mutations = datas
    },
    SET_GUDANG_TUJUAN(state, datas) {
      state.gudang_tujuans = datas
    },
  },
  actions: {
    async printMutasi({}, params) {
      try {
        const config = {
          method: 'get',
          url: '/cetak-asset-mutasi',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    async getRequestMutation({ commit }, params) {
      try {
        const config = {
          method: 'get',
          url: '/asset-mutasi',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_REQUEST_MUTATION', result)
        return Promise.resolve(JSON.parse(JSON.stringify(result)))
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getEntryMutation({ commit }, params) {
      try {
        const config = {
          method: 'get',
          url: '/asset-mutasi',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_ENTRY_MUTATION', result)
        return Promise.resolve(JSON.parse(JSON.stringify(result)))
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async saveRequestMutation({}, payload) {
      try {
        const config = {
          method: 'post',
          url: '/input/asset-mutasi',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
  },
}
