import service from '@/services/index'

export default {
  state: {
    pengadaans: [],
    rincians: [],
    currentTotalPengadaan: 0,
  },
  getters: {
    searchPengadaan: state => id => state.pengadaans.find(item => item.id == id),
  },
  mutations: {
    SET_PENGADAAN(state, datas) {
      state.pengadaans = datas
    },
    SET_PENGADAAN_RINCIAN(state, datas) {
      state.rincians = datas
    },
    SET_CURRENT_TOTAL_PENGADAAN(state, data) {
      state.currentTotalPengadaan = data
    },
  },
  actions: {
    async getPengadaan({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          url: '/asset-pengadaan',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_PENGADAAN', result)
        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getPengadaanById({}, id) {
      try {
        const config = {
          method: 'get',
          url: `/asset-pengadaan/${id}`,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getRincianPengadaan({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          url: '/asset-pengadaan-rincian',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_PENGADAAN_RINCIAN', result)
        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async savePengadaanRincian({}, payload) {
      try {
        const config = {
          url: '/input/asset-pengadaan-rincian',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async savePengadaan({}, payload) {
      try {
        const config = {
          url: '/input/asset-pengadaan',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async approvePengadaan({}, payload) {
      try {
        const config = {
          url: '/asset-pengadaan-approve',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async approvePengadaanRincian({}, payload) {
      try {
        const config = {
          url: '/asset-pengadaan-rincian-approve',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async cancelPengadaan({}, payload) {
      try {
        const config = {
          url: '/asset-pengadaan-cancel',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },

  },
}
