import service from '@/services'

export default {
  namespaced: true,
  state: {
    datas: [],
    checkedSetoran: [], // untuk dimasukan  ke kas / akun
    akunOptions: [],
    kasOptions: [],
    summarize: {
      tunai: [],
      transfer: [],
      giro: [],
      nota_putih: [],
    },
  },
  getters: {
    getBiayaOperasional: state => id => state.datas.find(item => item.id == id),
    getSummarize: state => {
      const tunai = state.summarize.tunai.reduce((total, payment) => total += payment.nominal, 0)
      const transfer = state.summarize.transfer.reduce((total, payment) => total += payment.nominal, 0)
      const giro = state.summarize.giro.reduce((total, payment) => total += payment.giro, 0)
      const nota_putih = state.summarize.nota_putih.reduce((total, payment) => total += payment.nominal, 0)
      return {
        tunai,
        transfer,
        giro,
        nota_putih,
      }
    },
  },
  mutations: {
    SET_DATA_SUMMARIZE(state, data) {
      state.summarize = data
    },
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_CHECKED_SETORAN(state, data) {
      state.checkedSetoran = data
    },
    SET_DATA_AKUN(state, data) {
      state.akunOptions = data
    },
    SET_DATA_KAS(state, data) {
      state.kasOptions = data
    },
  },
  actions: {
    async sendMoney({}, payload) {
      try {
        const config = {
          url: '/transaksi-setor-approve',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          url: `/pembayaran/${id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/pembayaran',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/input/pembayaran',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getData2({ commit }, params = {}) {
      try {
        const config = {
          url: '/pembayaran-rincian',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save2({}, payload = {}) {
      try {
        const config = {
          url: '/input/pembayaran-rincian',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async berikan({}, payload = {}) {
      try {
        const config = {
          url: '/pembayaran-berikan',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async terima({}, payload = {}) {
      try {
        const config = {
          url: '/pembayaran-terima',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
