export default [
  {
    path: "/reward-master",
    name: "route-reward-master",
    component: () => import("@/views/pages/reward-master/List.vue"),
    meta: {
      module: "Reward Master",
      authRequired: true,
      pageTitle: "Reward Master",
      breadcrumb: [
        {
          text: "Reward Master",
          active: true,
        },
      ],
    },
  },
  {
    path: "/reward",
    name: "route-reward",
    component: () => import("@/views/pages/reward/List.vue"),
    meta: {
      module: "Reward",
      authRequired: true,
      pageTitle: "Reward",
      breadcrumb: [
        {
          text: "Reward",
          active: true,
        },
      ],
    },
  },
];
