export default [
  {
    path: "/komisi-affiliate",
    name: "route-komisi-affiliate",
    component: () => import("@/views/pages/komisi/affiliate/Index.vue"),
    meta: {
      module: "Komisi Affiliate",
      authRequired: true,
      pageTitle: "Komisi Affiliate",
      breadcrumb: [
        {
          text: "Komisi Affiliate",
          active: true,
        },
      ],
    },
  },
  {
    path: "/komisi-bulanan",
    name: "route-komisi-bulanan",
    component: () => import("@/views/pages/komisi/bulanan/Index.vue"),
    meta: {
      module: "Komisi Bulanan",
      authRequired: true,
      pageTitle: "Komisi Bulanan",
      breadcrumb: [
        {
          text: "Komisi Bulanan",
          active: true,
        },
      ],
    },
  },
  {
    path: "/komisi-cabang",
    name: "route-komisi-cabang",
    component: () => import("@/views/pages/komisi/cabang/Index.vue"),
    meta: {
      module: "Komisi Cabang",
      authRequired: true,
      pageTitle: "Komisi Cabang",
      breadcrumb: [
        {
          text: "Komisi Cabang",
          active: true,
        },
      ],
    },
  },
  {
    path: "/penarikan-komisi",
    name: "route-penarikan-komisi",
    component: () => import("@/views/pages/komisi/penarikan/Index.vue"),
    meta: {
      module: "Penarikan Komisi",
      authRequired: true,
      pageTitle: "Penarikan Komisi",
      breadcrumb: [
        {
          text: "Penarikan Komisi",
          active: true,
        },
      ],
    },
  },
];
