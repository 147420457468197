import service from '@/services'
import pengadaan from './pengadaan'
import penyimpanan from './penyimpanan'
import blok from './blok'
import peminjaman from './peminjaman'
import pengembalian from './pengembalian'
import penjualan from './penjualan'
import mutasi from './mutasi'
import audit from './audit'
import disposal from './disposal'
import perbaikan from './perbaikan'
import penyusutan from './penyusutan'

export default {
  namespaced: true,
  state: {
    barangs: [],
    kasOptions: [],
    akunOptions: [],
    ...penyusutan.state,
    ...pengadaan.state,
    ...penyimpanan.state,
    ...peminjaman.state,
    ...blok.state,
    ...pengembalian.state,
    ...penjualan.state,
    ...mutasi.state,
    ...audit.state,
    ...disposal.state,
    ...perbaikan.state,
  },
  getters: {
    searchBarang: state => id => state.barangs.find(item => item.id == id),
    ...penyusutan.getters,
    ...pengadaan.getters,
    ...penyimpanan.getters,
    ...peminjaman.getters,
    ...blok.getters,
    ...pengembalian.getters,
    ...penjualan.getters,
    ...mutasi.getters,
    ...audit.getters,
    ...disposal.getters,
    ...perbaikan.getters,
  },
  mutations: {
    SET_DATA_AKUN(state, datas) {
      state.akunOptions = datas
    },
    SET_DATA_KAS(state, datas) {
      state.kasOptions = datas
    },
    SET_BARANG(state, datas) {
      state.barangs = datas
    },
    ...penyusutan.mutations,
    ...pengadaan.mutations,
    ...penyimpanan.mutations,
    ...peminjaman.mutations,
    ...blok.mutations,
    ...pengembalian.mutations,
    ...penjualan.mutations,
    ...mutasi.mutations,
    ...audit.mutations,
    ...disposal.mutations,
    ...perbaikan.mutations,
  },
  actions: {
    async printQRCode({}, asset_id) {
      const config = {
        url: `/cetak-qrcode/${asset_id}`,
        method: 'get',
        headers: {
          Authorization: `${localStorage.tokenType} ${localStorage.token}`,
        },
        responseType: 'blob',
      }
      try {
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    async getQRCode({}, aset_id) {
      try {
        const config = {
          url: `/asset-qr-code/${aset_id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getBarangById({}, id) {
      try {
        const config = {
          url: `/asset/${id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getBarang({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          url: '/asset',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const { data, total } = response.data
        commit('SET_BARANG', data)
        return Promise.resolve({ data, total })
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/input/asset',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    ...penyusutan.actions,
    ...pengadaan.actions,
    ...penyimpanan.actions,
    ...peminjaman.actions,
    ...blok.actions,
    ...pengembalian.actions,
    ...penjualan.actions,
    ...mutasi.actions,
    ...audit.actions,
    ...disposal.actions,
    ...perbaikan.actions,
  },
}
