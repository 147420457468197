import service from '@/services'
import QueryString from 'qs'

export default {
  namespaced: true,
  state: {
    datas: [],
  },
  getters: {
    getData: state => id => state.datas.find(item => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_TOTAL(state, total) {
      state.totals = total;
    },
    SET_DATA_BARANG(state, data) {
      state.datas_barang = data
    },
    SET_TOTAL_BARANG(state, total) {
      state.totals_barang = total;
    },
  },
  actions: {
    // send data penerimaan barang
    async save({}, payload) {
      try {
        const config = {
          url: '/input/penerimaan',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async saveBarang({}, payload) {
      try {
        const config = {
          url: '/input/penerimaan-barang',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          url: `/penerimaan/${id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getDataBarang({ commit }, params = {}) {
      try {
        const config = {
          url: '/penerimaan-barang',
          method: 'get',
          params,
          paramsSerializer: params => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.total;
        commit("SET_DATA_BARANG", result);
        commit("SET_TOTAL_BARANG", result_total);
        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/penerimaan',
          method: 'get',
          params,
          paramsSerializer: params => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.total;
        commit("SET_DATA", result);
        commit("SET_TOTAL", result_total);

        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getNewNoInvoice({ commit }, params = {}) {
      try {
        const config = {
          url: '/penerimaan-invoice',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
  },
}
