export default [
  // Akun
  {
    path: '/akun',
    name: 'route-akun',
    component: () => import('@/views/pages/akun/List.vue'),
    meta: {
      module: 'Akun',
      authRequired: true,
      pageTitle: 'Akun',
      breadcrumb: [
        {
          text: 'Akun',
          active: true,
        },
      ],
    },
  },
  {
    path: '/akun/info/:id',
    name: 'route-info-akun',
    component: () => import('@/views/pages/akun/Info.vue'),
    meta: {
      module: 'Akun',
      authRequired: true,
      pageTitle: 'Info Akun',
      breadcrumb: [
        {
          text: 'Akun',
          to: '/akun',
        },
        {
          text: 'Info Akun',
          active: true,
        },
      ],
    },
  },
  {
    path: '/akun/:id',
    name: 'route-akun-detail',
    component: () => import('@/views/pages/akun/ListDetail.vue'),
    meta: {
      parent: '/akun',
      module: 'Akun',
      authRequired: true,
      pageTitle: 'Transaksi Akun',
      breadcrumb: [
        {
          text: 'Akun', to: '/akun',
        },
        {
          text: 'Transaksi Akun',
          active: true,
        },
      ],
    },
  },
  // Kategori
  {
    path: '/kategori-akun',
    name: 'route-kategori-akun',
    component: () => import('@/views/pages/akun-kategori/List.vue'),
    meta: {
      module: 'Kategori Akun',
      authRequired: true,
      pageTitle: 'Kategori Akun',
      breadcrumb: [
        {
          text: 'Akun', to: '/akun',
        },
        {
          text: 'Kategori Akun',
          active: true,
        },
      ],
    },
  },
  // Jurnal
  {
    path: '/jurnal',
    name: 'route-jurnal',
    component: () => import('@/views/pages/akun/Jurnal.vue'),
    meta: {
      parent: '/akun',
      module: 'Akun',
      authRequired: true,
      pageTitle: 'Jurnal',
      breadcrumb: [
        {
          text: 'Akun', to: '/akun',
        },
        {
          text: 'Jurnal',
          active: true,
        },
      ],
    },
  },
]
