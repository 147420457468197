export default [
  {
    path: "/token-expired",
    name: "token-expired",
    component: () => import("@/views/error/Error401.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/pages/authentication/Login-v1.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/kategori-harga",
    name: "kategori-harga",
    component: () => import("@/views/pages/kategori-harga/List.vue"),
    meta: {
      module: "Kategori Harga",
      authRequired: true,
      pageTitle: "Kategori Harga",
      breadcrumb: [
        {
          text: "Kategori Harga",
          active: true,
        },
      ],
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () =>
      import("@/views/pages/authentication/ForgotPassword-v1.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },

  {
    path: "/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
    meta: {
      module: "public",
      layout: "full",
      resource: "Auth",
    },
  },
];
