import service from '@/services'

export default {
  getters: {
    searchPerbaikan: state => id => state.repairAssets.find(item => item.id == id),
  },
  state: {
    repairAssets: [],
    currentPerbaikan: null,
    currentTotalPerbaikan: 0,
  },
  mutations: {
    SET_DATA(state, data) {
      state.repairAssets = data
    },
    SET_CURRENT_PERBAIKAN(state, data) {
      state.currentPerbaikan = data
    },
    SET_CURRENT_TOTAL_PERBAIKAN(state, total) {
      state.currentTotalPerbaikan = total
    },
  },
  actions: {
    async getPerbaikan({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          url: '/asset-perbaikan-main',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getPerbaikanById({}, id) {
      try {
        const config = {
          method: 'get',
          url: `/asset-perbaikan-main/${id}`,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getRincianPerbaikan({ commit }, params = {}) {
      try {
        const config = {
          method: 'get',
          url: '/asset-perbaikan',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []

        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async savePerbaikan({}, payload) {
      try {
        const config = {
          method: 'post',
          url: '/input/asset-perbaikan-main',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async approvePerbaikan({}, payload) {
      try {
        const config = {
          method: 'post',
          url: '/asset-perbaikan-approve',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async saveRincianPerbaikan({}, payload) {
      try {
        const config = {
          method: 'post',
          url: '/input/asset-perbaikan',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async completePerbaikan({}, payload) {
      try {
        const config = {
          method: 'post',
          url: '/input/asset-perbaikan-kembalikan',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

  },
}
