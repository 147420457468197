import { AbilityBuilder, Ability } from '@casl/ability'
import { initialAbility } from './config'

export function getSubject(route) {
  const accessMenu = JSON.parse(localStorage.accessMenu)

  // get main menu dynamically
  const currentMenu = accessMenu.find(item => {
    const hasChildren = item.children
    if (hasChildren) {
      return item.children.find(child => child.url == route.path)
    }

    return item.url == route.path
  })

  // if the current route is like /barang/add || /barang/edit, get the route first so that we have the right permission
  if (route.meta.parent) {
    const parentMenu = accessMenu.find(item => {
      const hasChildren = item.children
      if (hasChildren) {
        return item.children.find(child => child.url == route.meta.parent)
      }
      return item.url == route.meta.parent
    })

    if (parentMenu && parentMenu.children) {
      return parentMenu.title
    }
    return parentMenu ? parentMenu.title : null
  }

  if (currentMenu && currentMenu.children) {
    return currentMenu.title
  }
  return currentMenu ? currentMenu.title : null
}

export function getMyAbility(roles) {
  let abilities = []

  roles.map(item => {
    if (item.menu) {
      // check create action first for prevent duplicate
      const subject = item.menu.name
      abilities.push({
        action: 'read',
        subject,
      })
      const checkCreate = abilities.find(ab => ab.action == 'create' && ab.subject == subject)
      if (item.create && !checkCreate) {
        abilities.push({
          action: 'create',
          subject,
        })
      }

      // check update action allowed
      const checkUpdate = abilities.find(ab => ab.action == 'update' && ab.subject == subject)
      if (item.update && !checkUpdate) {
        abilities.push({
          action: 'update',
          subject,
        })
      }

      // check
      const checkDelete = abilities.find(ab => ab.action == 'delete' && ab.subject == subject)
      if (item.delete && !checkDelete) {
        abilities.push({
          action: 'delete',
          subject,
        })
      }

      if (item.sub_menu) {
        item.sub_menu.map(sb => {
          abilities.push({
            action: 'read',
            subject: sb.name,
          })

          const checkSubCreate = abilities.find(ab => ab.action == 'create' && ab.subject == sb.name)
          if (sb.create && !checkSubCreate) {
            abilities.push({
              action: 'create',
              subject: sb.name,
            })
          }

          const checkSubUpdate = abilities.find(ab => ab.action == 'update' && ab.subject == sb.name)
          if (sb.update && !checkSubUpdate) {
            abilities.push({
              action: 'update',
              subject: sb.name,
            })
          }

          const checkSubDelete = abilities.find(ab => ab.action == 'delete' && ab.subject == sb.name)
          if (sb.delete && !checkSubDelete) {
            abilities.push({
              action: 'delete',
              subject: sb.name,
            })
          }
        })
      }
    }
  })

  if (abilities.length < 1) {
    abilities = initialAbility
  }
  localStorage.setItem('abbs', JSON.stringify(abilities))

  return abilities
}
