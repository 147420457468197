import service from '@/services'

export default {
  state: {
    blok: [],
  },
  getters: {},
  mutations: {
    SET_BLOK(state, datas) {
      state.blok = datas
    },
  },
  actions: {
    async getBlok({ commit }, params) {
      try {
        const config = {
          method: 'get',
          url: '/asset-blok',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_BLOK', result)
        return Promise.resolve(JSON.parse(JSON.stringify(result)))
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async saveBlok({}, payload) {
      try {
        const config = {
          method: 'post',
          url: '/input/asset-blok',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
  },
}
