import service from '@/services'

export default {
  namespaced: true,
  state: {
    profit_and_loss: null,
    journals: null,
  },
  mutations: {
    SET_PROFIT_AND_LOSS(state, data) {
      state.profit_and_loss = data
    },
    SET_DATA_JOURNAL(state, data) {
      state.journals = data
    },
  },
  actions: {
    async getModal({}, params) {
      try {
        const config = {
          method: 'get',
          url: '/cetak-perubahan-modal',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    async getData({}, {
      url, tgl_awal, tgl_akhir, sales_id,
    }) {
      try {
        const params = {
          tgl_awal,
          tgl_akhir,
        }

        if (sales_id) params.sales_id = sales_id
        const config = {
          method: 'get',
          url,
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }

        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    async getProfitAndLoss({ commit }, params) {
      try {
        const config = {
          method: 'get',
          url: '/dashboard-view-laba-rugi',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        commit('SET_PROFIT_AND_LOSS', response.data)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    async getJournal({ commit }, params) {
      try {
        const config = {
          method: 'get',
          url: '/view-jurnal',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)
        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA_JOURNAL', result)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
  },
}
