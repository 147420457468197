export default [
  //AFFILIATE
  {
    path: '/member',
    name: 'route-member',
    component: () => import('@/views/pages/member/List.vue'),
    meta: {
      authRequired: true,
      module: 'Member',
      pageTitle: 'Member',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
