import service from '@/services'

export default {
  namespaced: true,
  state: {
    datas: [],
  },
  getters: {
    getBiayapengambilan: state => id => state.datas.find(item => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
  },
  actions: {
    async printAjuan({}, bop_id) {
      try {
        const config = {
          url: `/cetak-pengambilan-barang/${bop_id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          url: `/biaya-pengambilan/${id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/biaya-pengambilan',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/input/biaya-pengambilan',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getData2({ commit }, params = {}) {
      try {
        const config = {
          url: '/biaya-pengambilan-rincian',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save2({}, payload = {}) {
      try {
        const config = {
          url: '/input/biaya-pengambilan-rincian',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async berikan({}, payload = {}) {
      try {
        const config = {
          url: '/biaya-pengambilan-berikan',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async terima({}, payload = {}) {
      try {
        const config = {
          url: '/biaya-pengambilan-terima',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
