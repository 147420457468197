export default [
  // AJuan Barang
  {
    path: '/ajuanbarang',
    name: 'route-ajuan-barang',
    component: () => import('@/views/pages/ajuanbarang/List.vue'),
    meta: {
      module: 'Ajuan Barang',
      authRequired: true,
      pageTitle: 'Ajuan Barang',
      breadcrumb: [
        {
          text: 'Ajuan Barang',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ajuanbarang/detail/:id',
    name: 'route-ajuan-barang-detail',
    component: () => import('@/views/pages/ajuanbarang/ListDetail.vue'),
    meta: {
      parent: '/ajuanbarang',
      module: 'Ajuan Barang',
      authRequired: true,
      pageTitle: 'Rincian Ajuan Barang',
      breadcrumb: [
        {
          text: 'Ajuan Barang', to: '/ajuanbarang',
        },
        {
          text: 'Rincian Ajuan Barang',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ajuanbarang/download-attachment/:id',
    name: 'route-ajuan-barang-download',
    component: () => import('@/views/pages/ajuanbarang/Attachment.vue'),
    meta: {
      parent: '/ajuanbarang',
      authRequired: true,
      pageTitle: 'Surat Jalan Ajuan Barang',
      breadcrumb: [
        {
          text: 'Ajuan Barang', to: '/ajuanbarang',
        },
        {
          text: 'Surat Jalan Ajuan Barang',
          active: true,
        },
      ],
    },
  },
]
