import service from "@/services";

export default {
  namespaced: true,
  state: {
    datas: [],
    totals: 0,
    records: null,
  },
  getters: {
    getBarang: (state) => (id) => state.datas.find((item) => item.id == id),
    getKodeBarang: (state) => (kode) => state.datas.find((item) => item.kode == kode),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_TOTAL(state, total) {
      state.totals = total;
    },
  },
  actions: {
    async getDataById({}, id) {
      try {
        const config = {
          url: `/barang/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getDataByIdV2({}, id) {
      try {
        const config = {
          url: `/barangv2/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: "/barang",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data;
        const result_total = response.data.total;
        commit("SET_DATA", result);
        commit("SET_TOTAL", result_total);

        return Promise.resolve(result, result_total);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDataV2({ commit }, params = {}) {
      try {
        const config = {
          url: "/barangv2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.total;
        commit("SET_DATA", result);
        commit("SET_TOTAL", result_total);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: "/input/barang",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async save2({}, payload = {}) {
      try {
        const config = {
          url: "/input/barangv2",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getPhoto({ commit }, params = {}) {
      try {
        const config = {
          url: "/photo-barang",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getPhotoId({}, id) {
      try {
        const config = {
          url: `/photo-barang/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async savePhoto({}, payload = {}) {
      try {
        const config = {
          url: "/input/photo-barang",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
