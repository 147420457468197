export default {
  namespaced: true,
  state: {
    checkedDatas: [],
    myDataOnly: false,
  },
  mutations: {
    SET_CHECKED_BARANG(state, datas) {
      state.checkedDatas = datas
    },
    SET_MY_DATA_ONLY(state, data) {
      state.myDataOnly = data
    },
  },
  actions: {},
}
