import service from '@/services'

export default {
  namespaced: true,
  state: {
    datas: [],
    selected_stocks: [], // for preview admin gudang
    routes: [],
  },
  getters: {
    getData: state => id => state.datas.find(item => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_SELECTED_STOCK(state, datas) {
      state.selected_stocks = datas
    },
    SET_ROUTE_DATA(state, datas) {
      state.routes = datas
    },
  },
  actions: {
    async getDataById({}, id) {
      try {
        const config = {
          url: `/request-barang/${id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getDataGudang({ commit }, params = {}) {
      try {
        const config = {
          url: '/request-barang-gudang',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getRoute({ commit }, params = {}) {
      try {
        const config = {
          url: '/request-barang-rute',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_ROUTE_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/request-barang',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/input/request-barang',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async saveRoute({}, payload = {}) {
      try {
        const config = {
          url: '/input/request-barang-rute',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * Gudang membuat surat jalan
     * @param {*} array [{ request_id, id_gudang }]
     */
    async createSuratJalan({}, payload) {
      try {
        const config = {
          url: '/input/request-barang-surat',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * Gudang download surat jalan
     * @param /surat_jalan_id/gudang_id
     *
     */
    async printSuratJalan({}, params) {
      const { request_id, gudangId } = params
      try {
        const config = {
          url: `/cetak-surat-jalan/${request_id}/${gudangId}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
  },
}
