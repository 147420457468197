import service from '@/services/index'

// Module Vuex affiliator/member
export default {
  namespaced: true,
  state: {
    datas: [],
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_DATA_KABKO(state, data) {
      state.datas = data
    },
  },
  actions: {
    async getprovinsi({ commit }, params = {}) {
      try {
        const config = {
          url: '/wilayah/provinsi',
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          params,
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getkabko({ commit }, params = {}) {
      try {
        const config = {
          url: '/wilayah/kab-kota',
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          params,
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA_KABKO', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getkecamatan({ commit }, params = {}) {
      try {
        const config = {
          url: '/wilayah/kecamatan',
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          params,
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getkelurahan({ commit }, params = {}) {
      try {
        const config = {
          url: '/wilayah/kelurahan',
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          params,
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // async save({}, payload = {}) {
    //   try {
    //     const config = {
    //       url: '/input/affiliator/member',
    //       method: 'post',
    //       data: payload,
    //       headers: {
    //         Authorization: `${localStorage.tokenType} ${localStorage.token}`,
    //       },
    //     }

    //     const response = await service.sendRequest(config)

    //     return Promise.resolve(response.data)
    //   } catch (error) {
    //     return Promise.reject(error)
    //   }
    // },
  },
}
