export default [

  {
    path: '/ceklist',
    name: 'route-ceklist',
    component: () => import('@/views/pages/ceklist/List.vue'),
    meta: {
      authRequired: true,
      module: 'Ceklist',
      pageTitle: 'Ceklist',
      breadcrumb: [
        {
          text: 'Ceklist',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ceklist/rincian/:id',
    name: 'route-ceklist-rincian',
    component: () => import('@/views/pages/ceklist/CekList.vue'),
    meta: {
      parent: '/ceklist',
      authRequired: true,
      module: 'Ceklist',
      pageTitle: 'Ceklist',
      breadcrumb: [
        {
          text: 'Ceklist',
          to: '/ceklist',
        },
        {
          text: 'Rincian Ceklist Kendaraan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ceklist/riwayat/:id',
    name: 'route-ceklist-riwayat',
    component: () => import('@/views/pages/ceklist/Riwayat.vue'),
    meta: {
      parent: '/ceklist',
      authRequired: true,
      module: 'Ceklist',
      pageTitle: 'Ceklist',
      breadcrumb: [
        {
          text: 'Ceklist',
          to: '/ceklist',
        },
        {
          text: 'Ceklist Kendaraan',
          active: true,
        },
      ],
    },
  },
]
