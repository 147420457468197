export default {
  // Endpoints
  loginEndpoint: '/auth/user-login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/auth/refresh',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',
  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'token',
  storageRefreshTokenKeyName: 'token',
  initToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2Rldi1hcGkuYmltZWFzdGluZG9uZXNpYS5jb20vdjEvYXV0aC91c2VyLWxvZ2luIiwiaWF0IjoxNjYyNTQ0NzcwLCJleHAiOjE2NjI1NDgzNzAsIm5iZiI6MTY2MjU0NDc3MCwianRpIjoiS2MzNFhvODByTklua29pNSIsInN1YiI6IjEiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.ZOqKMXrb0E7-r_BwPutWkA7p5oxVF-BVtud-MjW_Id8',
  initUserData: {
    id: 1,
    no_hp: '082132847328',
    email: 'admin@gmail.com',
    pin: '$2y$10$MVazP7Z/ZrjEu6HmSZEoHego8t3K.yKIugSfTimvUzMEuv0yRG3cC',
    remember_token: null,
    level: {
      id: 1,
      nama_level: 'Admin',
    },
    karyawan: {
      id: 1,
      jabatan_id: 1,
      jk: 1,
      nik: '0',
      nip: '0',
      nama_lengkap: 'Admin',
      no_hp: '082132847328',
      email: 'admin@gmail.com',
      alamat: 'Jl. Bahagia',
    },
  },
}
