export default [
  {
    path: '/stock-in',
    name: 'route-stock-in',
    component: () => import('@/views/pages/stock-in/List.vue'),
    meta: {
      module: 'Stock In',
      authRequired: true,
      pageTitle: 'Data Stok',
    },
  },
  {
    path: '/stock-in/edit/:id',
    name: 'route-stock-in-edit',
    component: () => import('@/views/pages/stock-in/Store.vue'),
    meta: {
      parent: '/stock-in',
      module: 'Stock In',
      authRequired: true,
    },
  },

]
