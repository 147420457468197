import service from '@/services'

export default {
  namespaced: true,
  actions: {
    async save({}, payload) {
      try {
        const config = {
          url: '/input/notifikasi',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
