export default [
  {
    path: '/retur-konsumen',
    name: 'route-retur-konsumen',
    component: () => import('@/views/pages/retur-konsumen/List.vue'),
    meta: {
      module: 'Retur Konsumen',
      authRequired: true,
      pageTitle: 'Data Retur Konsumen',
    },
  },
  {
    path: '/retur-konsumen/detail/:id',
    name: 'route-retur-konsumen-detail',
    component: () => import('@/views/pages/retur-konsumen/Detail.vue'),
    meta: {
      parent: '/retur-konsumen',
      module: 'Retur Konsumen',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List Retur Konsumen',
          to: '/retur-konsumen',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
]
