import service from '@/services'
import QueryString from 'qs'

// Module Vuex kendaraan-ceklist
export default {
  namespaced: true,
  state: {
    datas: [],
    status: [],
  },
  getters: {
    search(state) {
      return id => state.datas.find(bop => bop.id == id)
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_STATUS(state, data) {
      state.status = data
    },
  },
  actions: {
    async printSisa({}, { jenis, bop_id }) {
      const linkName = jenis == 'reimburse' ? 'cetak-reimburse-bop' : 'cetak-pengembalian-bop'
      try {
        const config = {
          url: `/${linkName}/${bop_id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    async setorBOP({}, payload) {
      try {
        const config = {
          url: '/bop-setor',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async printLPJ({}, bop_id) {
      try {
        const config = {
          url: `/cetak-laporan-bop/${bop_id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    async printAjuan({}, bop_id) {
      try {
        const config = {
          url: `/cetak-ajuan-bop/${bop_id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/bop-ajuan',
          method: 'get',
          params,
          paramsSerializer: params => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getLpjItem({ commit }, params = {}) {
      try {
        const config = {
          url: '/get-item-lpj',
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.item ? response.data.item : []

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async dikembali({}, payload = {}) {
      try {
        const config = {
          url: '/bop-approve-pengembalian',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async direimburse({}, payload = {}) {
      try {
        const config = {
          url: '/bop-approve-reimburse',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async approve({}, payload = {}) {
      try {
        const config = {
          url: '/bop-approve-ajuan',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/input/bop-ajuan',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
